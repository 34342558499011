import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <p>{`Opacity serves multiple purposes in our design system:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hierarchy:`}</strong>{`  Different opacity levels help distinguish foreground elements from background elements, ensuring content is easily readable and important actions stand out.`}</li>
      <li parentName="ul"><strong parentName="li">{`Focus:`}</strong>{` We can draw the user's attention to specific areas by applying more opacity to interactive elements or those requiring immediate attention.`}</li>
      <li parentName="ul"><strong parentName="li">{`State:`}</strong>{` Changes in opacity can indicate the interaction state of the element - it's active (100% opacity) or disabled (40% opacity) state, providing valuable feedback to the user.`}</li>
      <li parentName="ul"><strong parentName="li">{`Atmosphere:`}</strong>{` Subtle opacity variations can add depth and visual interest to our interfaces, creating a more engaging user experience.`}</li>
    </ul>
    <h2 {...{
      "id": "recommended-usage"
    }}>{`Recommended Usage`}</h2>
    <p>{`Opacity tokens can be used in a few different ways, such as overlays, translucent background, disabled elements, and shadow transparency.`}</p>
    <h3 {...{
      "id": "opacity-tokens"
    }}>{`Opacity Tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.zero`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Complete transparency (Dev only)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0.4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.overlay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overlay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0.4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.contrast`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tooltips, Status Indicator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0.8`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.shadow.first`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First shadow level`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0.12`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.shadow.second`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Second shadow level`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0.08`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opacity.full`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fully opaque`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      